import { Router } from '@angular/router';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { TimeAgoPipe } from 'ngx-moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { filter, shareReplay, tap } from 'rxjs/operators';

import { Hub } from '@qwyk/models';
import { environment } from '@qwyk/hub/environment';
import { MagayaConnectionService } from '@qwyk/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthenticationFacade } from '@qwyk/hub/authentication';

import {
    slideDown,
    fadeInOutAnimation,
    fadeInDelayAnimation,
} from '../../helpers/reusableAnimations';
import { versions } from '../../../environments/versions';
import { MenuSideBarService } from '../../services/menu-side-bar.service';

@Component({
    selector: 'app-index-new',
    templateUrl: './index-new.component.html',
    styleUrls: ['./index-new.component.scss'],
    animations: [fadeInOutAnimation, fadeInDelayAnimation, slideDown],
})
export class IndexNewComponent implements OnInit, OnDestroy {

    private router = inject(Router);
    private toastr = inject(ToastrService);
    private timeagoPipe= inject(TimeAgoPipe);
    private translate = inject(TranslateService);
    public menuSideBar = inject(MenuSideBarService);
    private authentication: AuthenticationFacade = inject(AuthenticationFacade);
    private magayaConnectionService: MagayaConnectionService = inject(MagayaConnectionService);

    public year = new Date().getFullYear();
    public versionInfo = versions;
    public env = environment;
    public magayaConnectionStatus$ = this.magayaConnectionService
        .getMagayaConnectionStatus()
        .pipe(
            tap(status => this.bannerFactory(status)),
            filter((status: Hub.MagayaConnectionStatus) => status.severity === 'critical'),
            shareReplay()
        );
    public bannerMessage: string;
    public hasUserTooltipShown = false;
    public feedbackSite: string = this.env.feedbackSite;
    public menuItems: MenuItem[] | undefined;

    public user$ = this.authentication.user$;
    public userMeta$ = this.authentication.userMeta$;
    public isMagayaNetwork$ = this.authentication.isMagayaNetwork$;

    private userTooltip: NgbTooltip;
    private userSubscription: Subscription;

    ngOnInit() {
        this.userSubscription = this.user$.subscribe(user => {
            this.menuItems = [
                {
                    label: `${this.translate.instant('hub.menu.top-menu.user')}: ${user.email}`
                },
                {
                    label: `${this.translate.instant('hub.menu.top-menu.organization')}: ${user.organization_id} (${user.organization_name})`
                },
                {
                    label: `${this.translate.instant('hub.menu.top-menu.role')}: ${user.role_name}`
                },
                {
                    divider: true
                },
                {
                    label: this.translate.instant('hub.menu.top-menu.profile'),
                    icon: 'user',
                    command: () => this.router.navigate(['/user', 'profile'])
                },
                {
                    divider: true
                },
                {
                    label: this.translate.instant('hub.menu.top-menu.log-out'),
                    icon: 'lock',
                    command: () => this.logout()
                },
            ];
        });
    }

    public logout() {
        this.authentication.logout();
    }

    public tooltipLoaded(tooltip: NgbTooltip) {
        this.userTooltip = tooltip;
    }

    public resetImpersonation() {
        this.authentication.resetImpersonation();
    }

    private bannerFactory(status: Hub.MagayaConnectionStatus) {
        const title = this.translate.instant(
            'hub.home.magaya-connection-issue'
        );
        const message = `${
            status.message
        }<br /><br /><small>${this.translate.instant(
            'hub.home.last-checked'
        )} ${this.timeagoPipe.transform(
            status.updated_at
        )}<br />(${this.translate.instant(
            'hub.home.updates-every-15-minutes'
        )})</small>`;

        switch (status.severity) {
            case 'high':
                this.toastr.error(message, title, {
                    enableHtml: true,
                    timeOut: 1e4,
                });
                break;
            case 'critical':
                this.toastr.error(message, title, {
                    enableHtml: true,
                    timeOut: 1e4,
                });
                break;
            case 'low':
            case 'medium':
                this.toastr.warning(message, title, {
                    enableHtml: true,
                    timeOut: 1e4,
                });
                break;
        }
    }

    public ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }
}
