<div class="container-fluid py-3 pb-5 bg-light min-vh-100">
    <div class="small text-muted mb-3 border-bottom pb-2">
        <a [routerLink]="['/']" class="text-muted">DFP</a>
        &rsaquo;
        <a [routerLink]="['./']" class="text-muted font-weight-bold"
        >{{ 'hub.common.quotations' | translate }}</a>
    </div>

    <div class="d-flex justify-content-between align-items-center">
        <h3 class="font-weight-normal m-0">{{ 'hub.common.quotations' | translate }}</h3>
        <div>
            <button
                [routerLink]="['../', 'new']"
                class="btn btn-outline-primary"
            >
                <fa-icon [icon]="['far', 'plus']" class="mr-1"></fa-icon>
                {{ 'common.create' | translate }} {{ 'common.quotations.quotation' | translate | lowercase }}
            </button>
        </div>
    </div>

    <div class="mt-3" *ngIf="query$ | async; let query">
        <qwyk-quotation-list-search-form
            (search)="onSearch($event)"
            [disabled]="loadingQuotations$ | async"
            [query]="query"
            class="mb-3"
        ></qwyk-quotation-list-search-form>

        <p-table
            #table
            (onLazyLoad)="loadQuotationsLazy($event, query)"
            [columns]="selectedColumns"
            [reorderableColumns]="false"
            [first]="
                (pagination$ | async)?.per_page *
                ((pagination$ | async)?.current_page - 1)
            "
            [lazy]="true"
            [loading]="loadingQuotations$ | async"
            [paginator]="true"
            [resizableColumns]="true"
            [rowHover]="true"
            [rowsPerPageOptions]="[25, 50, 100, 200]"
            [rows]="(pagination$ | async)?.per_page"
            [totalRecords]="(pagination$ | async)?.total"
            [value]="quotations$ | async"
            class="shadow-sm rounded"
        >
            <ng-template let-columns pTemplate="header">
                <tr>
                    <ng-container *ngFor="let c of columns | arrayFilter: 'hidden': false">
                        <th pResizableColumn>{{ c.header }}</th>
                    </ng-container>
                </tr>
            </ng-template>

            <ng-template let-columns="columns" let-quotation pTemplate="body">
                <tr
                    [routerLink]="['../', quotation.id]"
                    style="cursor: pointer"
                >
                    <ng-container
                        *ngFor="let c of columns | arrayFilter: 'hidden': false"
                        [ngSwitch]="c.field"
                    >
                        <td *ngSwitchCase="'friendly_id'">
                            <a [routerLink]="['../', quotation.id]">{{
                                quotation.friendly_id
                            }}</a>
                        </td>

                        <td
                            *ngSwitchCase="'status'"
                            style="font-size: 1em; line-height: 1.2"
                        >
                            <qwyk-quotation-status-badge
                                [status]="quotation.status"
                            ></qwyk-quotation-status-badge>
                        </td>

                        <td *ngSwitchCase="'owner.name'">
                            <span
                                [ngbPopover]="headerUserPopoverContent"
                                class="text-underline-dashed"
                                placement="bottom"
                                triggers="mouseenter:mouseleave"
                            >
                                {{ quotation.owner.name }}</span
                            >
                            <ng-template #headerUserPopoverContent>
                                <qwyk-user-info-tooltip
                                        [user]="quotation.owner"
                                ></qwyk-user-info-tooltip>
                            </ng-template>

                            <div class="small">
                                {{ quotation.owner.team_name }}
                            </div>
                        </td>

                        <td *ngSwitchCase="'request.transport_mode'">
                            {{ quotation.request.transport_mode !== null ?
                                ('portal.shipment.transport-mode.' + quotation.request.transport_mode | translate | titlecase) : ''
                            }}
                            <span *ngIf="quotation.request.transport_mode !== 'AIR'">
                                {{ quotation.request.load_type | transportModeTransform: quotation.request.transport_mode | uppercase }}
                            </span>
                        </td>

                        <td *ngSwitchCase="'request.origin.type'">
                            {{
                            (quotation.request.origin.type === 'port'
                                ? 'common.port'
                                : 'common.door') | translate
                            }}
                        </td>
                        <td *ngSwitchCase="'request.destination.type'">
                            {{
                            (quotation.request.destination.type === 'port'
                                ? 'common.port'
                                : 'common.door') | translate
                            }}
                        </td>
                        <td *ngSwitchCase="'request.created_at'">
                            <span
                                [ngbTooltip]="quotation.request.created_at | amDateFormat: 'L LT'"
                            >
                                {{ quotation.request.created_at | amTimeAgo }}
                            </span>
                        </td>
                        <td *ngSwitchCase="'offers_count'">
                            {{ quotation.offers_count }}
                            <fa-icon
                                *ngIf="quotation.offers_count === 0"
                                [icon]="['far', 'exclamation-triangle']"
                                class="ml-1 text-warning"
                                size="sm"
                            ></fa-icon>
                        </td>
                        <td *ngSwitchDefault>
                            {{ quotation | traverse: (c.field | split: '.') }}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <ng-container *ngIf="pagination$ | async; let pagination">
                    {{ pagination.from }} - {{ pagination.to }} of
                    {{ pagination.total }}
                </ng-container>
            </ng-template>

            <ng-template let-selectedCols pTemplate="loadingbody">
                <tr>
                    <td
                        [attr.colspan]="(selectedCols | arrayFilter: 'hidden':false).length"
                        class="py-4 text-muted text-center"
                    >
                        {{ 'common.loading' | translate }}...
                    </td>
                </tr>
            </ng-template>

            <ng-template let-selectedCols pTemplate="footer">
                <tr>
                    <td
                        [attr.colspan]="(selectedCols | arrayFilter: 'hidden': false).length - 1"
                    >
                        <p-multiSelect
                            (onChange)="onSelectedColumnsChange()"
                            [(ngModel)]="selectedColumns"
                            [options]="columns | arrayFilterByObject: 'value' : 'hidden': false"
                            [style]="{ minWidth: '200px' }"
                            appendTo="body"
                            optionLabel="label"
                            optionValue="value"
                            [filter]="true"
                            [showToggleAll]="true"
                            [selectAll]="selectAll"
                            (onSelectAllChange)="onSelectAllChange($event)"
                            placeholder="{{ 'common.choose-columns' | translate }}"
                            selectedItemsLabel="{{ 'common.select-columns' | translate }}"
                        ></p-multiSelect>
                    </td>
                    <td class="text-right">
                        <button
                            (click)="table.exportCSV()"
                            class="btn btn-primary btn-sm"
                        >
                            <fa-icon
                                [icon]="['far', 'cloud-download']"
                                class="mr-1"
                            ></fa-icon>
                            {{ 'common.download' | translate }}
                        </button>
                    </td>
                </tr>
            </ng-template>

            <ng-template let-selectedColumns pTemplate="emptymessage">
                <tr>
                    <td
                        [attr.colspan]="(selectedColumns | arrayFilter: 'hidden':false).length"
                        class="py-4 text-muted text-center"
                    >
                        {{ 'common.nothing_found' | translate }}
                        <div>
                            <button
                                [routerLink]="['../', 'new']"
                                class="btn btn-outline-primary btn-sm mt-3"
                            >
                                <fa-icon
                                    [icon]="['far', 'plus']"
                                    class="mr-1"
                                ></fa-icon>
                                {{ 'common.create' | translate }} {{ 'common.quotations.quotation' | translate | lowercase }}
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
